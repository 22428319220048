import { useEffect, useState } from "react";

const ItemWidget: React.FC<{ item: any }> = ({ item }) => {
  const [row, setRow] = useState(item);
  const [flashCount, setFlashCount] = useState(-1);
  const [flash, setFlash] = useState(false);
  useEffect(() => {
    setRow(item);
  }, [item]);

  useEffect(() => {
    const flashMultiply = 5;
    if (row.isNew) setFlashCount(2 * flashMultiply);
  }, [row]);

  useEffect(() => {
    if (flashCount > 0) {
      setFlash(flashCount % 2 === 0);
      setTimeout(() => {
        setFlashCount((prev) => prev - 1);
      }, 300);
    }
  }, [flashCount]);

  return (
    <tr key={row._id} className={flash ? "highlight" : ""}>
      <td className="w-25">{row.name}</td>
      <td className="w-25">{row.amount}</td>
      <td>{row.message}</td>
    </tr>
  );
};

export default ItemWidget;

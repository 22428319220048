import React, { useEffect, useState } from "react";
import "./App.css";
import * as Realm from "realm-web";
import ItemWidget from "./item";

const REALM_APP_ID = "tipmealert-nhxow";
const realm = new Realm.App({ id: REALM_APP_ID });
interface Item {
  _id: string;
  name: string;
  amount: string;
  message: string;
  created_at: string;
  isNew?: boolean;
}

const App: React.FC = () => {
  const [queue, setQueue] = useState<any[]>([]);
  const [items, setItems] = useState<Item[] | null>(null);
  const [user, setUser] = useState<Realm.User | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await realm.logIn(Realm.Credentials.anonymous());
        setUser(user);
        console.log("logged in successfully!");
      } catch (error) {
        console.error("Error logging in:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!user) return;
    const mongodb = user?.mongoClient("mongodb-atlas");
    const collection = mongodb.db("tipme").collection("alert");

    const fetchItems = async () => {
      const items = await collection.aggregate([
        { $sort: { created_at: 1 } },
        { $limit: 10 },
      ]);
      setItems(items);
    };
    fetchItems();

    const watch = async () => {
      const stream = collection.watch({});
      const iterator = stream[Symbol.asyncIterator]();
      const watching = async () => {
        while (true) {
          const change = await iterator.next();
          if (change.done) {
            break;
          }
          setQueue((prevQueue) => [...prevQueue, change.value]);
        }
      };
      watching();
      console.log("set watch listener successfully!");
    };

    watch();
  }, [user]);

  useEffect(() => {
    console.log(`queue changed to ${queue.length} items`);
    if (queue.length === 0) return;
    const change = queue[0];
    const fullDocument = change.fullDocument;
    fullDocument.isNew = true;
    if (items) {
      const itemIndex = items.findIndex(
        (item) => item._id.toString() === fullDocument._id.toString()
      );
      const currentItems = [...items];
      if (itemIndex !== -1) {
        currentItems.splice(itemIndex, 1, fullDocument);
      } else {
        currentItems.unshift(fullDocument);
      }
      setItems(currentItems);
    } else {
      setItems([fullDocument]);
    }

    setQueue((prevQueue) => prevQueue.slice(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue]);

  return (
    <div className="App" id="driverLoging">
      <div className="App-header">
        <table className="table table-striped">
          <tbody>
            {items ? (
              items.map((item) => <ItemWidget key={item._id} item={item} />)
            ) : (
              <tr>
                <td>Loading...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default App;
